import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import * as $ from 'jquery';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  ngAfterViewInit() {
    // setTimeout(() => {
    //   Swal.fire({
    //     customClass: {
    //       container: 'swal-css'
    //     },
    //     title: '',
    //     text: '',
    //     showCancelButton: true,
    //     confirmButtonText: 'GO AHEAD',
    //     // cancelButtonText: 'CANCEL',
    //     position: 'top',
    //     // icon: 'success',
    //     // title: 'Your work has been saved',
    //     showConfirmButton: false,
    //     // timer: 5500
    //   });
    // }, 3000);
    $('document').ready(function () {

      $('#google_translate_element').on("click", function () {

          $("iframe").contents().find(".goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div, .goog-te-menu2 *")
              .css({
                  'color': '#544F4B',
                  'font-family': 'Roboto',
                  // 'width':'100%'
              });
          // $("iframe").contents().find('.goog-te-menu2-item-selected').css ('display', 'none');

          // $("iframe").contents().find('.goog-te-menu2').css ('padding', '0px');

          $("iframe").contents().find('.goog-te-menu2-item div').css('padding', '6px');

          // $("iframe").contents().find('.goog-te-menu2-item').css('width', '100%');
          // $("iframe").contents().find('td').css('width', '100%');

          $("iframe").contents().find(".goog-te-menu2-item div").hover(function () {
              $(this).css('background-color', '#369AF8').find('span.text').css('color', 'white');
          }, function () {
              $(this).css('background-color', 'white').find('span.text').css('color', '#544F4B');
          });

          $("iframe").contents().find('.goog-te-menu2').css('border', 'none');

          $(".goog-te-menu-frame").css('box-shadow', '0 16px 24px 2px rgba(54,154,248, 0.14), 0 6px 30px 5px rgba(54,154,248, 0.12), 0 8px 10px -5px rgba(54,154,248, 0.3)');

          $(".goog-te-menu-frame").css({
              // 'height': '50%',
              'width': '100%',
              // 'top': '0px'
          });
          $("iframe").contents().find('.goog-te-menu2').css({
              'height': '100%',
              'width': '100%',
              'overflow-y': 'auto'
          });
      });
      });
  }
}
