<div class="basic modal fade" tabindex="-1" role="dialog" aria-hidden="true" [ngClass]="{'in': true}"
  [ngStyle]="{'display': true ? 'block' : 'none', 'opacity': true ? 1 : 0}">
  <div class="modal-dialog" role="document" [ngClass]="">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        <form class="px-md-5 py-5" (ngSubmit)="onSubmit(obj)">
          <div class="px-3">
            <div class="text-center">
              <h5 class="f-20 mb-5">Create New Event</h5>
            </div>
            <div class="form-group row">
              <div class="col">
                <label class="hoverable" for="fileInput">
                  <img *ngIf="image" [src]="image" alt="Logo" width="100" height="100">
                  <div class="hover-text text-center"><span>+</span><div class="f-12">Add Featured Image</div></div>
                  <div class="background"></div>
                </label>
                <input class="avatar-file-upload d-none"  id="fileInput" type='file' (change)="onSelectFile($event.target.files)"
                  [ngClass]="!validationError.image ? '':  'is-invalid'">
                <div class="invalid-feedback" *ngIf="validationError && validationError.image">
                  {{validationError.image}}</div>
              </div>
            </div>
            <div class="form-group ">
              <label class="text-grey f-12 font-weight-bold" for="event_name">Event Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="obj.event_name" name="event_name" id="event_name"
                placeholder="Title" [ngClass]="!validationError.event_name ? '':  'is-invalid'" (click)="validationError.event_name = ''">
              <div class="invalid-feedback" *ngIf="validationError && validationError.event_name">
                {{validationError.event_name}}
              </div>
            </div>
            <div class="form-group " *ngIf="false">
              <label class="text-grey f-12 font-weight-bold" for="event_type_id">Event Type <span class="text-danger">*</span></label>
              <select placeholder="Type" class="form-control" [(ngModel)]="obj.event_type_id" name="event_type_id"
                [ngClass]="!validationError.event_type_id ? '':  'is-invalid'" >
                <option value="1" *ngIf="role == 1">Killerspin</option>
                <option value="2">Company</option>
                <!-- <option value="3">Personal</option> -->
              </select>
              <!-- <input type="text" class="form-control" [(ngModel)]="obj.event_type_id" name="event_type_id" id="event_type_id" placeholder="Type"
                [ngClass]="!validationError.event_type_id ? '':  'is-invalid'"> -->
              <div class="invalid-feedback" *ngIf="validationError && validationError.event_type_id">
                {{validationError.event_type_id}}
              </div>
            </div>
            <div class="form-group ">
              <label class="text-grey f-12 font-weight-bold" for="event_date">Event Date <span class="text-danger">*</span></label>
              <app-date-time-picker [(ngModel)]="obj.event_date" name="event_date" (click)="validationError.event_date = ''"
                [ngClass]="!validationError.event_date ? '':  'is-invalid'" #startDate (dateFormat)="startDate.value = $event" 
                [maxDate]="endDate.value"></app-date-time-picker>
              <!-- <input type="date" class="form-control" [(ngModel)]="obj.date" name="date" id="date" placeholder="Date"
                [ngClass]="!validationError.date ? '':  'is-invalid'"> -->
              <div class="invalid-feedback" *ngIf="validationError && validationError.event_date">
                {{validationError.event_date}}
              </div>
            </div>

            <div class="form-group ">
              <label class="text-grey f-12 font-weight-bold" for="end_date">End Date <span class="text-danger">*</span></label>
              <app-date-time-picker [(ngModel)]="obj.end_date" name="end_date" (click)="validationError.end_date = ''"
                [ngClass]="!validationError.end_date ? '':  'is-invalid'" #endDate (dateFormat)="endDate.value = $event" 
                [minDate]="startDate.value" [disabled]="!startDate.value"></app-date-time-picker>
              <div class="invalid-feedback" *ngIf="validationError && validationError.end_date">
                {{validationError.end_date}}
              </div>
            </div>

            <div class="form-group ">
              <label class="text-grey f-12 font-weight-bold" for="location_id">Location <span class="text-danger">*</span></label>
              <select placeholder="Location" class="form-control" [(ngModel)]="obj.location_id" name="location_id"
                [ngClass]="!validationError.location_id ? '':  'is-invalid'" >
                <option value="undefined" hidden>Select Location</option>
                <option [value]="loc.id" *ngFor="let loc of allLocation">{{loc.name}}</option>
              </select>
              <div class="invalid-feedback" *ngIf="validationError && validationError.location_id">
                {{validationError.location_id}}
              </div>
            </div>

            <div class="form-group ">
              <label class="text-grey f-12 font-weight-bold" for="description">Description <span class="text-danger">*</span></label>
              <textarea rows="2" class="form-control" [(ngModel)]="obj.description" name="description"
                placeholder="Description" [ngClass]="!validationError.description ? '':  'is-invalid'" (click)="validationError.description = ''"></textarea>
              <div class="invalid-feedback" *ngIf="validationError && validationError.description">
                {{validationError.description}}
              </div>
            </div>

            <div class="form-group mt-4" *ngIf="role == 1 || role == 2">
              <div class="row" ngbRadioGroup name="event_type_id" [(ngModel)]="obj.event_type_id">
                <div class="col-sm d-inline-flex">
                  <div ngbButtonLabel class="radio-item">
                    <input ngbButton type="radio" id="comp" [value]="2" class="mr-2">
                    <label for="comp" class=""></label>
                  </div>
                  <div class="w-100">
                    <select placeholder="Select" class="form-control py-1 h-auto" [(ngModel)]="obj.company_id"
                      name="company_id" [ngClass]="!validationError.company_id ? '':  'is-invalid'" [disabled]="loading1">
                      <option value="undefined" hidden>Select</option>
                      <option [value]="company.id" *ngFor="let company of allCompany">{{company.company_name}}
                      </option>
                    </select>
                    <span class="spinner-border spinner-border-sm text-dark-red" *ngIf="loading1"></span>
                    <div class="invalid-feedback" *ngIf="validationError && validationError.company_id">
                      {{validationError.company_id}}
                    </div>
                  </div>
                </div>
                <div class="col-sm">
                  <div ngbButtonLabel class="radio-item">
                    <input ngbButton type="radio" id="kill" [value]="1" class="mr-2">
                    <label for="kill" class="">Killerspin</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-4 mt-5">
              <button type="submit" class="btn btn-block btn-dark-red" [disabled]="loading">
                <span class="spinner-border spinner-border-sm" *ngIf="loading"></span>&nbsp; <span *ngIf="loading">
                  Loading..</span>
                <span *ngIf="!loading">Create</span>
              </button>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
