import { AuthService } from 'src/app/components/auth-guard/auth.service';
import { CoreService } from 'src/app/services/core.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';

import * as _ from 'lodash';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-company-event-form',
  templateUrl: './company-event-form.html'
})
export class CompanyEventFormModal implements OnInit {

  @Input() obj: any = {
    event_type_id: 2,
    is_public: 0,
  };
  notifyData: any = {};
  validationError: any = {};
  formData: any = new FormData();
  loading = false;
  image: any;
  allLocation: any = [];
  allCompany: any = [];
  loading1 = false;

  options = {
    types: [],
    componentRestrictions: { country: '' }
  }
  imageFlag = false;
  role;
  @Input() public item;
  constructor(private coreService: CoreService, public activeModal: NgbActiveModal, private authService: AuthService) {
    this.obj.user_id = authService.uId;
    if(authService.userData) {
      this.role = authService.userData.role_id;
      if(this.role == 3) {
        this.obj.company_id = authService.userData.company_id;
      }
    }
    this.getAllLocation();
    this.getAllCompany();

  }

  ngOnInit() {
    if(this.item){
      this.obj.location_id=this.item;
    }
    
  }

  getAllLocation() {
    this.coreService.get('admin/location/active').subscribe((res: any) => {
      this.allLocation = res.data;
    }, err => {
    });
  }
  getAllCompany() {
    this.loading1 = true;
    this.coreService.get('admin/company').subscribe((res: any) => {
      this.allCompany = res.data;
      this.loading1 = false;
      let active=[];
      this.allCompany.forEach(element => {
        
        if(element.deleted_at==null){
          active.push(element)
        }
      });
      this.allCompany = active;
     
      
      
    }, err => {
    });
  }

  onSelectFile(img) {
    if(img[0].type.includes('image','pdf')) {
      this.obj.image = img[0];
      this.imageFlag = true;
      var reader = new FileReader();
      reader.readAsDataURL(img[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.image = event.target.result;
      }
      this.validationError.image = '';
    } else {
          this.validationError.image = 'Please select image file';     
      }
  }
  onSubmit(obj) {
    if (this.validateInputs(obj)) {
      obj.is_public = obj.event_type_id == 1 ? 1 : 0; 
      this.loading = true;
      // if(obj.is_public){
      //   delete obj['company_id'];
      // }
      if(this.imageFlag) {
        for (const key in obj) {
          if (key) {
            this.formData.append(key, obj[key]);
          }
        }
        this.coreService.post('event/create', this.formData).subscribe((res: any) => {
          this.loading = false;
          Swal.fire('', 'Event created successfully', 'success');
          this.activeModal.close();
        }, err => {
          this.loading = false;
        });
      } else {
        this.coreService.post('event/create', obj).subscribe((res: any) => {
          this.loading = false;
          Swal.fire('', 'Event created successfully', 'success');
          this.activeModal.close();
        }, err => {
          this.loading = false;
        });
      }
    }
  }

  validateInputs(obj) {
    
    this.validationError = {};
    // let passRegex = /^(?=.*\d).{8,20}$/;
    let myRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let phoneRegex = /[1-9]{2}\d{8}/;

    if (this.image ? this.image.trim() === '' : true) {
      this.validationError.image = 'Please select event image';
    }
    if (obj.event_name ? obj.event_name.trim() === '' : true) {
      this.validationError.event_name = 'Please enter event name';
    }
    // if (obj.event_type_id ? (obj.event_type_id === 'undefined') : true) {
    //   this.validationError.event_type_id = 'Please select event type';
    // }
    if ( (obj.event_date ? (obj.event_date.trim() === '') : true) ) {
      this.validationError.event_date = 'Please select event date and time';
    }
    if ( (obj.end_date ? (obj.end_date.trim() === '') : true) ) {
      this.validationError.end_date = 'Please select event end date and time';
    }
    if (obj.event_date > obj.end_date) {
      this.validationError.end_date = 'Please select valid date and time';
    }
    if (obj.event_type_id == 2 ? (obj.company_id == undefined) : false) {
      this.validationError.company_id = 'Please select company';
    }
    if (obj.location_id ? (obj.location_id === 'undefined') : true) {
      this.validationError.location_id = 'Please select Location';
    }

    if (obj.description ? (obj.description.trim() === '') : true) {
      this.validationError.description = 'Please enter description';
    }

    if (Object.keys(this.validationError).length) {
      return false;
    } else {
      this.validationError = {};
      return true;
    }
  }

}

