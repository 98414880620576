import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './components/auth-guard/auth.guard';
import { AdminComponent } from './theme/layout/admin/admin.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'ksp',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(module => module.ProfileModule), canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('./modules/dash-board/dash-board.module').then(module => module.DashBoardModule), canActivate: [AuthGuard]},
      { path: 'users', loadChildren: () => import('./modules/user-list/user-list.module').then(module => module.UserListModule), canActivate: [AuthGuard] },
      { path: 'companies', loadChildren: () => import('./modules/companies/companies.module').then(module => module.CompaniesModule), canActivate: [AuthGuard] },
	    { path:'location', loadChildren:() => import('./modules/location/location.module').then(module=>module.LocationModule), canActivate: [AuthGuard] },
	    { path:'gamification', loadChildren:() => import('./modules/gamification/gamification.module').then(module=>module.GamificationModule), canActivate: [AuthGuard] },
	    { path:'coupons', loadChildren:() => import('./modules/coupons/coupons.module').then(module=>module.CouponsModule), canActivate: [AuthGuard] }
    ]
  },
  {
    path: 'manager',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(module => module.ProfileModule), canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('./modules/dash-board/dash-board.module').then(module => module.DashBoardModule), canActivate: [AuthGuard]},
      { path: 'users', loadChildren: () => import('./modules/user-list/user-list.module').then(module => module.UserListModule), canActivate: [AuthGuard] },
      { path: 'companies', loadChildren: () => import('./modules/companies/companies.module').then(module => module.CompaniesModule), canActivate: [AuthGuard] },
	    { path:'location', loadChildren:() => import('./modules/location/location.module').then(module=>module.LocationModule), canActivate: [AuthGuard] }
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(module => module.ProfileModule), canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('./modules/dash-board/dash-board.module').then(module => module.DashBoardModule), canActivate: [AuthGuard]},
      { path: 'users', loadChildren: () => import('./modules/user-list/user-list.module').then(module => module.UserListModule), canActivate: [AuthGuard] },
      // { path: 'companies', loadChildren: () => import('./modules/companies/companies.module').then(module => module.CompaniesModule), canActivate: [AuthGuard] },
	    // { path:'location', loadChildren:() => import('./modules/location/location.module').then(module=>module.LocationModule), canActivate: [AuthGuard] }
    ]
  },
  {
    path: 'user',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(module => module.ProfileModule), canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('./modules/dash-board/dash-board.module').then(module => module.DashBoardModule), canActivate: [AuthGuard]},
    ]
  },
  {
    path: 'coach',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(module => module.ProfileModule), canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('./modules/dash-board/dash-board.module').then(module => module.DashBoardModule), canActivate: [AuthGuard]},
    ]
  },
  {
    path: 'ui',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(module => module.ProfileModule), canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('./modules/dash-board/dash-board.module').then(module => module.DashBoardModule), canActivate: [AuthGuard]},
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(module => module.LoginModule), canActivate: [AuthGuard]
  },
  {
    path: 'register/:token',
    loadChildren: () => import('./modules/register/register.module').then(module => module.RegisterModule)
  },
  {
    path: 'event/res/:id',
    loadChildren: () => import('./modules/register/register.module').then(module => module.RegisterModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(module => module.ForgotPasswordModule)
  },
  {
    path: 'reset/password/:token',
    loadChildren: () => import('./modules/reset/reset.module').then(module => module.ResetModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
