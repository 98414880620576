<div class="input-group mr-2">
	<input
    [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"
    class="form-control"
    (blur)="inputBlur($event)"
    [ngModel]="dateString | date:inputDatetimeFormat"
    (change)="onInputChange($event)"
    [disabled]="disabled"
    placeholder="Date"
    readonly
  />

	<div class="input-group-append">
		<button
      class="btn btn-outline-dark-red"
      [ngbPopover]="calendarContent"
      [disabled]="disabled"
      type="button"
      container="body"
    >
      <!-- <fa-icon [icon]="['far', 'calendar']"></fa-icon> -->
      <i class="far fa-calendar"></i>
    </button>
	</div>
</div>

<ng-template #calendarContent>
	<div>
		<div *ngIf="!showTimePickerToggle">
			<ngb-datepicker id="dp" #dp name="datepicker" [ngModel]="datetime"
				(ngModelChange)="onDateChange($event, dp)" [maxDate]="maxDate" [minDate]="minDate"></ngb-datepicker>
			<button
        class="btn btn-block btn-outline-dark-red"
        [disabled]="!datetime?.day"

        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <!-- <fa-icon [icon]="['far', 'clock']"></fa-icon> -->
        <i class="far fa-clock"></i>
      </button>
		</div>
		<div *ngIf="showTimePickerToggle">
			<button
        class="btn btn-block btn-outline-dark-red"
        [ngbPopover]="calendarContent"
        type="button"
        (click)="toggleDateTimeState($event)"
      ><!--[ngbPopover]="timePickerContent"-->
        <!-- <fa-icon [icon]="['far', 'calendar']"></fa-icon> -->
        <i class="far fa-calendar"></i>
      </button>
			<div class="mt-auto">
				<ngb-timepicker #tp name="timepicker" [ngModel]="datetime" [meridian]="meridian" (ngModelChange)="onTimeChange($event)"
					[seconds]="seconds" [hourStep]="hourStep" [minuteStep]="minuteStep" [secondStep]="secondStep">
				</ngb-timepicker>
			</div>
		</div>
	</div>
</ng-template>
