import { AuthService } from './../auth-guard/auth.service';
import { CoreService } from 'src/app/services/core.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.html',
  styleUrls: ['./event-form.scss']

})
export class EventFormModal implements OnInit {
  step = 1;
  memberFlag = 1;
  progress = 0;
  searchTerm:string;
  userData: any = [];
  userFilter = {
    page: 1,
    perpage: 25,
  };
  deleted:any=[];
  selectedData:any=[];
  details:any=[];
  deselect:boolean=false;
  event: any = {
    event_type_id: 3,
    is_public: 0,
    member_invited: 0,
    non_members: [],
    description: 'NA',
  };
  allLocation: any = [];
  allTable: any = [];
  nonMember: any = {};
  formData: any = new FormData();
  image: any;
  validationError: any = {};
  nonMemberValidErr: any = {};
  loading = false;
  companyId;
  available = true;
  @Input() public obj:any;
  locId: any;
  updatedObj: any;
  data: any;
  
  constructor(private coreService: CoreService, private authService: AuthService, private modalService: NgbModal, public activeModal: NgbActiveModal) {
    this.event.user_id = authService.uId;
    if(authService.userData) {
      this.companyId = authService.userData.company_id;
    }
    this.getAllLocation();
    this.getUserList();
    // this.getAllTable();
  }

  ngOnInit(): void {
    if(this.obj){
    this.event.location_id=this.obj;
   
    }else{
      
    }
 
  }

  getAvailablity(obj) {
    this.coreService.post('event/available', {event_date: obj.event_date, end_date: obj.end_date, table_id: obj.table_id}).subscribe((res: any) => {
      if(res.data) {
        this.available = res.data.available;
      }
    }, err => {
    });
  }
  getAllLocation() {
    this.coreService.get('admin/location/active').subscribe((res: any) => {
      this.allLocation = res.data;      
    }, err => {
    });
  }

  getAllTable(locationId) {
    this.event.table_id = undefined;
    if(this.companyId) {
      this.coreService.get('admin/table/private/actives/'+this.companyId+ '/'+locationId).subscribe((res: any) => {
        this.allTable = res.data;
      }, err => {
      });
    } else {
      this.coreService.get('admin/table/public/actives/'+ locationId).subscribe((res: any) => {
        this.allTable = res.data;
      }, err => {
      });
    }
  }

  getUserList() {
    if(this.companyId) {
      this.coreService.get('admin/company/detail/'+this.companyId).subscribe((res: any) => {
        if(res.data){
          this.userData = res.data.company_users;
        }
      }, err => {
      });
    } else {
      this.coreService.get('users/general').subscribe((res: any) => {
        if(res.data){
          this.userData = res.data;
        }
      }, err => {
      });
    }
  }

  onSelectFile(img) {
    if(img[0].type.includes('image')) {
      this.event.image = img[0];
      this.progress = 25;
      var reader = new FileReader();
      reader.readAsDataURL(img[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.image = event.target.result;
      }
      this.validationError.image = '';
    } else {
          this.validationError.image = 'Please select image file';     
      }
  }

  addingNonMember(obj) {
    if (this.nonMemberValidate(obj)) {
      if(this.event.member_invited < 3) {
        obj.role = 6;
        obj.company_id = this.companyId;
        this.event.non_members.push(obj);
        this.details.push(obj);
        this.nonMember = {};
        this.step = 4;
        this.event.member_invited+=1;
      }
    }
  }

  onSubmit(obj) {
      this.loading = true;
      if(this.image) {
        for (const key in obj) {
          if (key) {
            this.formData.append(key, obj[key]);
          }
        }
        this.coreService.post('event/create', this.formData).subscribe((res: any) => {
          this.inviteUsers(res.data.id);
          this.loading = false;
          this.activeModal.close();
          Swal.fire('',res.message, 'success')
        }, err => {
          this.loading = false;
        });
      } else {
        this.coreService.post('event/create', obj).subscribe((res: any) => {
          this.inviteUsers(res.data.id);
          this.loading = false;
          this.activeModal.close();
          Swal.fire('',res.message, 'success')
        }, err => {
          this.loading = false;
        });
      }
    // }
  }

  inviteUsers(id) {
    this.selectedData.forEach(memberId => {
      this.coreService.post('event/invite/member', {user_id: memberId, event_id: id}).subscribe((res: any) => {
      }, err => {
      });
    });
    this.event.non_members.forEach(member => {
      member.event_id = id;
      this.coreService.post('admin/company/invite', member).subscribe((res: any) => {
      }, err => {
      });
    });
  }

  validateInputs(obj) {
    this.validationError = {};
    if (this.step == 1) {
      if (this.image ? this.image.trim() === '' : true) {
        this.validationError.image = 'Please select event image';
      }
      if (obj.event_name ? obj.event_name.trim() === '' : true) {
        this.validationError.event_name = 'Please enter event name';
      }
    } else if(this.step == 2) {
        this.getAvailablity(obj);
      if (obj.location_id ? (obj.table_id === undefined) : false) {
        this.validationError.table_id = 'Please select table';
      }
      if (obj.event_date ? (obj.event_date.trim() === '') : true ) {
        this.validationError.event_date = 'Please select event date and time';
      }
      if (obj.end_date ? (obj.end_date.trim() === '') : true ) {
        this.validationError.end_date = 'Please select event end date and time';
      }
      
      if (obj.event_date > obj.end_date) {
        this.validationError.end_date = 'Please select valid date and time';
      }
      if (this.available ? false : true ) {
        obj.table_id = undefined;
        this.validationError.table_id = 'Table is not available for this time period. choose different time or table';
      }
      // if (obj.is_public == 0 ? (obj.selected_company == undefined) : false) {
      //   this.validationError.selected_company = 'Please select company';
      // }
      if (obj.location_id ? (obj.location_id === 'undefined') : true) {
        this.validationError.location_id = 'Please select Location';
      }
    } else if(this.step == 4) {
      if (this.details ? (this.details.length == 0) : true) {
        this.validationError.location_id = 'Please select at least 1 user';
      }
    }

    if (Object.keys(this.validationError).length) {
      return false;
    } else {
      this.progress = this.progress+25;
      this.step = this.step+1;
      this.validationError = {};
      return true;
    }
  }

  nonMemberValidate(obj) {
    this.nonMemberValidErr = {};
    let phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    let myRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (obj.first_name ? obj.first_name.trim() === '' : true) {
      this.nonMemberValidErr.first_name = 'Please enter first name';
    }
    if (obj.last_name ? obj.last_name.trim() === '' : true) {
      this.nonMemberValidErr.last_name = 'Please enter last name';
    }
    if (obj.email ? obj.email.trim() === '' : true) {
      this.nonMemberValidErr.email = 'Please enter email';
    } else if (!myRegEx.test(obj.email)) {
      this.nonMemberValidErr.email = 'Please enter valid email';
    }

    if (Object.keys(this.nonMemberValidErr).length) {
      return false;
    } else {
      this.nonMemberValidErr = {};
      return true;
    }
  }

  onChange(id,isChecked){
    if(isChecked){
      if(this.event.member_invited < 3) {
        this.selectedData.push(id);
        this.event.member_invited+=1;
        this.details.splice(0,this.details.length);
      }
    } else{
        this.selectedData.forEach((element,index)=>{
          if(element==id)
            this.selectedData.splice(index,1);
        });
        this.details.splice(0,this.details.length);
        this.event.member_invited -= 1;

    }

      for(let i=0;i<this.userData.length;i++ ){
        for(let j=0;j<this.selectedData.length;j++){

          if(this.userData[i].id==this.selectedData[j]){
           this.details.push(this.userData[i]);
          }
        }
      }
}

  unCheck(val,index){
    this.deleted.push(val);
    this.details.splice(index,1);
    if(!val) {
      this.event.non_members.splice(index, 1);
    }
    this.event.member_invited-=1;
    for(let l=0;l<this.selectedData.length;l++){
      for(let m=0;m<this.deleted.length;m++){
      if(this.selectedData[l]==this.deleted[m]){
        this.selectedData.splice(l,1);
      }
    }
  }
  this.userData.forEach((user,p)=>{
    this.deleted.forEach((data,q)=>{
      if(user.id==data){
        user.isselected=false;
      }
    });
  });
      this.deleted.splice(0,this.deleted.length);
}
clearLocation(){
  this.event['location_id']=''
}
}
