import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NextConfig} from '../../../../app-config';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  public nextConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;
  url;
  title: string = 'Welcome';

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();

  constructor(public router: Router, private titleService: Title) {
    this.nextConfig = NextConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;
    this.router.events.subscribe((ev) => {
    this.url = (this.router.url).split('/')[1];
      if (ev instanceof NavigationEnd) {
        this.getTitle();
      }
    });
  }

  ngOnInit() {
    window.onresize = () => {
      this.responsiveView();
    };
  }

  getTitle() {
    if (this.url == 'ksp') {
      this.title = 'Killerspin Admin';
    } else if(this.url == 'manager') {
      this.title = 'Community Manager';
    } else if(this.url == 'admin') {
      this.title = 'Company Admin';
    } else {
      this.title = 'Dashboard';
    }
    this.titleService.setTitle(this.title + ' | KSP');
  }

  responsiveView() {
    // this.nextConfig = NextConfig.config;
    this.windowWidth = window.innerWidth;
  }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
    } else {
      this.onNavHeaderMobCollapse.emit();
    }
  }

}
