<div id="http-loader" *ngIf="isSpinnerVisible">
    <div class="loader-bg">
        <!-- material-line -->
        <div class="sk-line-material" [class.colored]="!backgroundColor" *ngIf="spinner === Spinkit.skLine">
            <div class="sk-child sk-bounce1" [style.background-color]='backgroundColor'></div>
        </div>
        <!-- <div role="status" class="spinner-border" [style.color]='backgroundColor'>
          <span class="sr-only">Loading...</span>
        </div> -->
        <!-- <div role="status" class="spinner-grow" [style.background-color]='backgroundColor'>
          <span class="sr-only">Loading...</span>
        </div> -->
    </div>
</div>
