import { ChangeDetectorRef, Injectable, Pipe, PipeTransform, NgZone, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';
import { AsyncPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  private campaignFilterNew = new Subject<any>();
  private spinnertoggle = new Subject<any>();
  filterNew$ = this.campaignFilterNew.asObservable();
  spinner$ = this.spinnertoggle.asObservable();

  constructor(private http: HttpClient) { }

  get(url) {
    return this.http.get<any>(url);
  }

  getObj(url, obj) {
    return this.http.get<any>(url, obj);
  }

  post(url, object) {
    return this.http.post(url, object);
  }

  postBlob(url, object) {
    return this.http.post(url, object, {observe: 'response', responseType: 'blob'});
  }

  postTable(url, size, page) {
    return this.http.post(url, size, page);
  }

  put(url, object) {
    return this.http.put(url, object);
  }

  delete(url) {
    return this.http.delete(url);
  }

  filterMethod(e) {
    this.campaignFilterNew.next(e);
  }

  spinnerShow(e) {
    this.spinnertoggle.next(e);
  }

  sort(key, b, array) {
    let dummyArray = [];
    if (array.length > 0) {
      if (b === 'ascend') {
        dummyArray = _.orderBy(array, key, 'asc');
      } else if (b === 'descend') {
        dummyArray = _.orderBy(array, key, 'desc');
      }
    }
    return dummyArray;
  }



}
@Pipe({
  name: 'phoneFormat'
})
export class PhoneForamtPipe implements PipeTransform {

  transform(num: string): any {
    if(num) {
      var x = num.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      if (x !== null && x !== undefined) {
        num = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
      }
      return num;
    }
  }
}

@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoExtPipe implements PipeTransform, OnDestroy {
  changeDetectorRef: ChangeDetectorRef;
  ngZone: NgZone;
  timer: any;

  constructor(changeDetectorRef: ChangeDetectorRef, ngZone: NgZone) {
    this.changeDetectorRef = changeDetectorRef;
    this.ngZone = ngZone;
  }

  ngOnDestroy() {
    this.removeTimer();
  }

  transform(value: any, args?: any): any {
    this.removeTimer();
    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return 'a few seconds ago';
    } else if (seconds <= 90) {
      return 'a minute ago';
    } else if (minutes <= 45) {
      return minutes + ' minutes ago';
    } else if (minutes <= 90) {
      return 'an hour ago';
    } else if (hours <= 22) {
      return hours + ' hours ago';
    } else if (hours <= 36) {
      return 'a day ago';
    } else if (days <= 25) {
      return days + ' days ago';
    } else if (days <= 45) {
      return 'a month ago';
    } else if (days <= 345) {
      return months + ' months ago';
    } else if (days <= 545) {
      return 'a year ago';
    } else {
      // (days > 545)
      return years + ' years ago';
    }
  }

  removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  getSecondsUntilUpdate(seconds) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;

    if (seconds < min) {
      // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    } else {
      // update every hour
      return 3600;
    }
  }
}
