<div class="container" *ngIf="this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <div (window:resize)="responsiveView()" class="m-header ">
    <!-- <a href="javascript:" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a> -->
    <a [routerLink]="['./dashboard']" class="b-brand">
      <img src="assets/images/KSP Logo.png" class="img-fluid logo logo-position" style="max-height: 50px;">
      <img src="assets/images/logo-icon.png" alt="" class="logo-thumb">
    </a>
    <a [routerLink]="['./dashboard']" class="ml-4 ml-md-5 d-lg-block">
      <h4 class="text-dark text-truncate">{{title}} </h4>
    </a>
    <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" (click)="toggleMobOption()"><i class="feather icon-more-vertical"></i></a>
  </div>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <!-- <app-nav-left class="mr-auto"></app-nav-left> -->
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</ng-template>
