import { Component, NgZone, OnInit } from '@angular/core';
import { NextConfig } from '../../../app-config';
import { Location } from '@angular/common';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public nextConfig: any = NextConfig.config;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;

  constructor(private zone: NgZone, private location: Location) {
    this.responsiveView();

  }

  ngOnInit() {
    window.onresize = () => {
      this.responsiveView();
    };
  }

  responsiveView() {
    let currentURL = this.location.path();
    const baseHerf = this.location['_baseHref'];
    if (baseHerf) {
      currentURL = baseHerf + this.location.path();
    }

    this.windowWidth = window.innerWidth;

    if ((currentURL === baseHerf + '/layout/collapse-menu'
      || currentURL === baseHerf + '/layout/box')
      && (this.windowWidth >= 992 && this.windowWidth <= 1024)) {
      this.nextConfig.collapseMenu = true;
    }

    this.navCollapsed = (this.windowWidth >= 992) ? this.nextConfig.collapseMenu : false;
    this.navCollapsedMob = false;
    if (this.windowWidth < 992) {
      // this.nextConfig.layout = 'vertical';
      // this.nextConfig.navFixedLayout = true;
      // this.nextConfig.headerFixedLayout = true;
      setTimeout(() => {
        // document.querySelector('.pcoded-navbar').classList.add('menupos-static');
        // (document.querySelector('#nav-ps-next') as HTMLElement).style.maxHeight = '100%'; // 100% amit
      }, 500);
    } else {
      this.nextConfig.layout = 'horizontal';
      this.nextConfig.navFixedLayout = false;
      this.nextConfig.headerFixedLayout = false;
    }
  }

  navMobClick() {
    // if (this.windowWidth < 992) {
    //   if (this.navCollapsedMob && !(document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
    //     this.navCollapsedMob = !this.navCollapsedMob;
    //     setTimeout(() => {
    //       this.navCollapsedMob = !this.navCollapsedMob;
    //     }, 100);
    //   } else {
    //     this.navCollapsedMob = !this.navCollapsedMob;
    //   }
    // }
  }

}
