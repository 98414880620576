<ul class="navbar-nav ml-auto">
  <li>
    <div>
      <button class="btn btn-sm btn-outline-dark-red mx-sm-2"  (click)=" events()" id="play">
        PLAY
      </button>
    </div>
  </li>
  <li *ngIf="role == 3">
    <div class="dropdown" ngbDropdown placement="auto">
      <button class="btn btn-sm btn-outline-dark-red mx-sm-2 l-h-sm" [routerLink]="['./users']" >
        <span *ngIf="role == 3">ADMIN</span>
      </button>
    </div>
  </li>
  <li *ngIf="role < 3">
    <div class="dropdown" ngbDropdown placement="auto">
      <button ngbDropdownToggle data-toggle="dropdown" id="adminMenu"
        class="btn btn-sm btn-outline-dark-red mx-sm-2 l-h-sm">
        ADMIN
      </button>
      <div ngbDropdownMenu aria-labelledby="adminMenu" class="dropdown-menu-right mr-0">
        <ul class="pro-body pl-0">
          <li class="dropdown-item cursor-pointer" [routerLink]="['./users']" routerLinkActive="router-link-active">Manage Users</li>
          <li class="dropdown-item cursor-pointer" [routerLink]="['./companies']" routerLinkActive="router-link-active">Manage Companies</li>
          <li class="dropdown-item cursor-pointer" [routerLink]="['./location']" routerLinkActive="router-link-active">Manage Locations</li>
          <li class="dropdown-item cursor-pointer" [routerLink]="['./gamification']" routerLinkActive="router-link-active">Manage Badges</li>
          <!-- <li *ngIf="role == 1" class="dropdown-item cursor-pointer" [routerLink]="['./coupons']" routerLinkActive="router-link-active">Manage Coupons</li> -->
        </ul>
      </div>
    </div>
  </li>
  <!-- <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown" class="mx-sm-2">
        <i class="icon feather icon-user-plus f-18"></i>
      </a>
    </div>
  </li> -->
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown" class="mx-sm-2">
        <!-- <i class="icon feather icon-bell f-18"></i></a> -->
        <i class="icon far fa-bell f-18 text-grey"></i></a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-hea p-3 border-bottom">
          <h6 class="d-inline-block m-b-0">Notifications</h6>
          <div class="float-right">
            <!-- <a href="javascript:" class="m-r-10">mark as read</a> -->
            <!-- <a href="javascript:">Clear All</a> -->
          </div>
        </div>
        <perfect-scrollbar [style.max-height]="'300px'">
          <ul class="noti-body">
            <!-- <li class="n-title">
            <p class="m-b-0">NEW</p>
          </li> -->
          <ng-container *ngFor="let data of notifications | slice: (filter.page-1) * filter.perpage : (filter.page-1) * filter.perpage + filter.perpage">
            <li class="notification" *ngIf="data.invite_by.id != currentUser.id">
              <div class="media">
                <img class="img-radius hei-40" [src]="data.invite_by.avatar ? data.invite_by.avatar: 'assets/images/user/avatar.png'" alt="Generic placeholder image">
                <div class="media-body">
                  <p>{{data.invite_by ? data.invite_by.first_name+' '+data.invite_by.last_name: ''}} has {{data.message | lowercase}} <strong>{{data.event ? data.event.event_name: ''}}</strong>
                    <!-- <span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>5 min</span> -->
                  </p>
                  <p class="">
                    <img src="assets/images/svg/img1.svg" class="wid-12 mr-2">
                    <span class="text-grey f-12">{{data.event ? data.event.location ? data.event.location.name: 'NA': 'NA'}}</span>
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <img src="assets/images/svg/img2.svg" class="wid-12 mr-2">
                      <span class="text-grey f-12 "> {{data.event ? (data.event.event_date | date:'mediumDate'): 'NA'}}</span>
                    </div>
                    <div class="col-6">
                      <img src="assets/images/svg/img3.svg" class="wid-12 mr-2">
                      <span class="f-12 text-grey">{{data.event ? (data.event.event_date | date:'shortTime'): ''}}</span>
                    </div>
                  </div>

                  <p class="mt-2">
                    <span class="f-12 text-muted">{{data.updated_at | timeAgo}}</span>
                    <span class="float-right f-12" *ngIf="false">
                      <span class="text-grey cursor-pointer red p-2">Accept</span>
                      <span class="text-grey cursor-pointer red p-2">Decline</span>
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ng-container>

            <!-- <li class="n-title">
            <p class="m-b-0">EARLIER</p>
          </li> -->
          </ul>
        </perfect-scrollbar>
        <!-- <div class="noti-footer">
          <a href="javascript:">show all</a>
        </div> -->
      </div>
    </div>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown" class="mx-sm-2" [routerLink]="['./profile']">
        <!-- <i class="icon feather icon-user"></i> -->
        <img *ngIf="!currentUser.avatar" src="/assets/images/user/avatar.png" width="30px" height="30px"
          class="img-radius" alt="User-Profile-Image">
        <img *ngIf="currentUser.avatar" [src]="currentUser.avatar" width="30px" height="30px" class="img-radius"
          alt="User-Profile-Image">
      </a>
      <!-- <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img *ngIf="!currentUser.avatar" src="/assets/images/user/avatar.png" class="img-radius" alt="User-Profile-Image">
          <img *ngIf="currentUser.avatar" [src]="currentUser.avatar" class="img-radius" alt="User-Profile-Image">
          <span>{{currentUser.name | titlecase}}</span>
          <a href="javascript:" class="dud-logout" title="Logout"> -->
      <!-- <i class="feather icon-log-out"></i> -->
      <!-- </a>
        </div>
        <ul class="pro-body">
          <li><a href="javascript:" class="dropdown-item" [routerLink]="['/profile']" routerLinkActive="router-link-active"><i class="feather icon-user"></i> Profile</a></li> -->
      <!-- <li><a href="javascript:" class="dropdown-item" [routerLink]="['/settings']" routerLinkActive="router-link-active"><i class="feather icon-settings"></i> Settings</a></li> -->
      <!-- <li><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a></li> -->
      <!-- <li><a href="javascript:" class="dropdown-item" (click)="logout()"><i class="feather icon-log-out"></i> Log out</a></li>
        </ul>
      </div> -->
    </div>
  </li>
</ul>







