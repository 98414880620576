import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'POST' || req.method === 'GET' || req.method === 'PUT' || req.method == 'DELETE') {
      if (req.url.match('login')) {
        const user = req.body;
        req = req.clone({
          url: environment.baseURL + req.url,
          headers: req.headers.set('Authorization', 'No Auth ' + btoa(unescape(user.email + ':' + user.password))),
        });
      } else {
        req = req.clone({
          url: environment.baseURL + req.url,
          headers: req.headers.set('Authorization', 'Bearer ' + this.authService.accessTokenId)
        });
      }

      return next.handle(req).pipe(
        tap(event => {

        }, err => {
          // if ((err.status === 401 || err.status === 440) && this.router.url !== '/login') {
          //   let title = 'Session expired. Please login again';
          //   if (req.url.match('getUser')) {
          //   }
          //   this.authService.clearLocalStorage();
          //   return this.router.navigate(['/login']);
          // } else if (err.status && err.status !== 434) {
          //   if (err.error.error) {
          //   }
          // }
        })
      );
    } else {
      return next.handle(req);
    }
    // return next.handle(req);
  }
}
