import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (state.url.match('/login')) {
        if (this.authService.accessTokenId) {
          if (this.authService.userData.role_id == 1) {
            this.router.navigate(['ksp']);
          } else if (this.authService.userData.role_id == 2) {
            this.router.navigate(['manager']);
          } else if (this.authService.userData.role_id == 3) {
            this.router.navigate(['admin']);
          } else if (this.authService.userData.role_id == 4) {
            this.router.navigate(['user']);
          } else if (this.authService.userData.role_id == 5) {
            this.router.navigate(['coach']);
          } else {
            this.router.navigate(['ui']);
          }
          return false;
        } else {
          return true;
        }
      }
      if (this.authService.userData ? this.authService.userData.role_id == 1 : false) {
        if (state.url.match('/ksp')) {
          if (this.authService.accessTokenId) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (this.authService.userData ? this.authService.userData.role_id == 2 : false) {
        if (state.url.match('/manager')) {
          if (this.authService.accessTokenId) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (this.authService.userData ? this.authService.userData.role_id == 3 : false) {
        if (state.url.match('/admin')) {
          if (this.authService.accessTokenId) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (this.authService.userData ? this.authService.userData.role_id == 4 : false) {
        if (state.url.match('/user')) {
          if (this.authService.accessTokenId) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (this.authService.userData ? this.authService.userData.role_id == 5 : false) {
        if (state.url.match('/coach')) {
          if (this.authService.accessTokenId) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (this.authService.userData ? this.authService.userData.role_id == 6 : false) {
        if (state.url.match('/ui')) {
          if (this.authService.accessTokenId) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      // not logged in so redirect to login page with the return url and return false
      this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
      return false;
    // return true;
  }

}
