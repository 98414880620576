<!-- <div (window:resize)="responsiveView()"></div>
<app-navigation class="pcoded-navbar" [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.nextConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}" (onNavMobCollapse)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light " (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container">
    <div class="pcoded-content" [ngClass]="{'container': this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'}">
      <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
</div>
<app-configuration></app-configuration> -->
<div (window:resize)="responsiveView()"></div>
<!-- <app-navigation class="pcoded-navbar printPageButton" [ngClass]="{'navbar-collapsed' : (navCollapsed && windowWidth >= 992), 'theme-horizontal': this.nextConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob, 'd-none' : windowWidth >= 992}" (onNavMobCollapse)="navMobClick()"></app-navigation> -->
<app-nav-bar class="printPageButton navbar pcoded-header navbar-expand-lg navbar-light bg-white navbar-shadow" (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container container-margin  pt-0">
    <div class="pcoded-content p-3" style="margin-top: 0px;" [ngClass]="{'container': this.nextConfig.layout === 'horizontal' && this.nextConfig.subLayout === 'horizontal-2'}">
      <!-- <app-breadcrumb></app-breadcrumb> -->
      <router-outlet ></router-outlet>
    </div>
</div>
<app-configuration></app-configuration>
