import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public accessTokenId;
  public userData;
  public uId;

  constructor() {
    this.accessTokenId = localStorage.accessTokenId;
    this.uId = localStorage.uId;
    if(localStorage.userData) {
      this.userData = JSON.parse(localStorage.userData);
    }
  }
  setLocalStorage(data) {
    this.accessTokenId = data.token;
    this.userData = JSON.stringify(data.data);
    this.uId = data.data.id;
  }
  setRegisterData(data) {
    this.accessTokenId = data.token;
    this.userData = JSON.stringify(data.data);
    this.uId = data.data.id;
  }
  save(){
    localStorage.setItem('accessTokenId', this.accessTokenId);
    localStorage.setItem('userData', this.userData);
    localStorage.setItem('uId', this.uId);
    this.userParse();
  }
  userParse() {
    if (localStorage.userData) {
      this.userData = JSON.parse(localStorage.userData);
    }
  }
  clearLocalStorage() {
    localStorage.removeItem('accessTokenId');
    localStorage.removeItem('userData');
    this.accessTokenId = null;
    this.userData = null;
  }
}
