import { PhoneForamtPipe, TimeAgoExtPipe } from './../../services/core.service';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule, BreadcrumbModule, CardModule } from './components';
import { DataFilterPipe } from './components/data-table/data-filter.pipe';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';
import { SpinnerComponent } from './components/spinner/spinner.component';
import {LightboxModule} from 'ngx-lightbox';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from '@iplab/ngx-file-upload';

import { HeaderCardsComponent } from 'src/app/components/header-cards/header-cards.component';
import { UsersComponent } from 'src/app/components/users/users.component';

import { InvitationComponent } from 'src/app/components/invitation/invitation.component';

import { ComEventsComponent } from 'src/app/components/com-events/com-events.component';
import { FindComponent } from 'src/app/components/find/find.component';
import { PersonalEventsComponent } from 'src/app/components/personal-events/personal-events.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
import { CompanyEventFormModal } from 'src/app/components/company-event-form/company-event-form';
import { CompaniesComponent } from 'src/app/modules/companies/companies.component';

import { ImageCropperModule } from 'ngx-image-cropper';


/*import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';*/

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DataTablesModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ClickOutsideModule,
    LightboxModule,
    NgbModule,
    FileUploadModule,
    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAEiXo-aE-Brcw3njb5IwoTVDJ2fPCGpjE'
    }),
    ImageCropperModule
  
  ],
  entryComponents: [CompanyEventFormModal],

  exports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DataTablesModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    DataFilterPipe,
    PhoneForamtPipe,
    TimeAgoExtPipe,
    // TodoListRemoveDirective,
    // TodoCardCompleteDirective,
    ClickOutsideModule,
    SpinnerComponent,
    NgbModule,
    FileUploadModule,
    ComEventsComponent,
    HeaderCardsComponent,
    UsersComponent,
    PersonalEventsComponent,
    FindComponent,
    InvitationComponent,
    DateTimePickerComponent,
    GooglePlaceModule,
    AgmCoreModule,
    
    ImageCropperModule,

    
  ],
  declarations: [
    DataFilterPipe,
    PhoneForamtPipe,
    TimeAgoExtPipe,
    // TodoListRemoveDirective,
    // TodoCardCompleteDirective,
    SpinnerComponent,
    ComEventsComponent,
    HeaderCardsComponent,
    UsersComponent,
    PersonalEventsComponent,
    FindComponent,
    InvitationComponent,
    DateTimePickerComponent,
    CompanyEventFormModal,
    CompaniesComponent
  

  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
  ],
})
export class SharedModule { }
