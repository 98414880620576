<div class="basic modal fade" tabindex="-1" role="dialog" aria-hidden="true" [ngClass]="{'in': true}"
  [ngStyle]="{'display': true ? 'block' : 'none', 'opacity': true ? 1 : 0}">
  <div class="modal-dialog modal-md" role="document" [ngClass]="">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>

        <div class="px-2 px-md-5">
          <div>
            <ngb-progressbar class="mt-5" [showValue]="true" type="danger" height="20px" [value]="progress">
            </ngb-progressbar>
          </div>
          <div class="pt-3 row " style="min-height: 250px;">

            <div class="col-12 align-self-center" *ngIf="step == 1">
              <div class="animated fadeIn">
                <form>
                  <div class="form-group ">
                    <label class="hoverable" for="fileInput">
                      <img *ngIf="image" [src]="image" alt="Logo" width="100" height="100">
                      <div class="hover-text text-center"><span>+</span><div class="f-12">Add Featured Image</div></div>
                      <div class="background"></div>
                    </label>
                    <input class="avatar-file-upload d-none"  id="fileInput" type='file' (change)="onSelectFile($event.target.files)"
                      [ngClass]="!validationError.image ? '':  'is-invalid'">
                    <div class="invalid-feedback" *ngIf="validationError && validationError.image">
                      {{validationError.image}}</div>
                  </div>
                  <div class="form-group ">
                    <label class="text-grey f-12 font-weight-bold" for="event_name">Event Name</label>
                    <input type="text" class="form-control" [(ngModel)]="event.event_name" name="event_name" id="event_name"
                      placeholder="Title" [ngClass]="!validationError.event_name ? '':  'is-invalid'" (click)="validationError.event_name = ''">
                    <div class="invalid-feedback" *ngIf="validationError && validationError.event_name">
                      {{validationError.event_name}}
                    </div>
                  </div>

                </form>
              </div>
            </div>

            <div class="col-12 align-self-center" *ngIf="step == 2">
              <div class="animated fadeIn">
                <form>
                  <div class="form-group ">
                    <label class="text-grey f-12 font-weight-bold" for="location_id">Location</label>
                    <select type="text" class="form-control" [(ngModel)]="event.location_id" name="location_id" (change)="getAllTable(event.location_id)"
                      placeholder="Location" [ngClass]="!validationError.location_id ? '':  'is-invalid'">
                      <option value="undefined" hidden > <span class="f-14 light-text text-muted">Location</span></option>
                      <option [ngValue]="data.id" *ngFor=" let data of allLocation">{{data.name}}</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="validationError && validationError.location_id">
                      {{validationError.location_id}}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="text-grey f-12 font-weight-bold" for="event_date">Event Date</label>
                    <app-date-time-picker [(ngModel)]="event.event_date" name="event_date" (click)="validationError.event_date = ''"
                      [ngClass]="!validationError.event_date ? '':  'is-invalid'"  #startDate (dateFormat)="startDate.value = $event" 
                      [maxDate]="endDate.value"></app-date-time-picker>
                    <div class="invalid-feedback" *ngIf="validationError && validationError.event_date">
                      {{validationError.event_date}}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="text-grey f-12 font-weight-bold" for="end_date">End Date</label>
                    <app-date-time-picker [(ngModel)]="event.end_date" name="end_date" (click)="validationError.end_date = ''"
                      [ngClass]="!validationError.end_date ? '':  'is-invalid'" #endDate (dateFormat)="endDate.value = $event" 
                      [minDate]="startDate.value" [disabled]="!startDate.value"></app-date-time-picker>
                    <div class="invalid-feedback" *ngIf="validationError && validationError.end_date">
                      {{validationError.end_date}}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="text-grey f-12 font-weight-bold" for="table_id">Table</label>
                    <select type="text" class="form-control" [(ngModel)]="event.table_id" name="table_id" [disabled]="(!event.location_id) || !(event.end_date && event.end_date != '')"
                      placeholder="Table" [ngClass]="!validationError.table_id ? '':  'is-invalid'" (change)="getAvailablity(event)">
                      <option value="undefined" hidden > <span class="f-14 light-text text-muted">Table</span></option>
                      <option [ngValue]="data.id" *ngFor=" let data of allTable">{{data.name+', '+data.building}}</option>
                      <option [ngValue]="" *ngIf="!allTable.length" disabled>No Table Found</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="validationError && validationError.table_id">
                      {{validationError.table_id}}
                    </div>
                  </div>
                </form>

              </div>
            </div>

            <div class="col-12 " style="z-index: 99;" *ngIf="step == 3">
              <div class="animated fadeIn">
                <div class="text-center mt-3">
                  <form>
                    <div class="" ngbRadioGroup name="memberFlag" [(ngModel)]="memberFlag">
                      <!-- <div class="col-sm d-inline-flex"> -->
                        <div ngbButtonLabel class="radio-item mr-4">
                          <input ngbButton type="radio" id="member-section" [value]="1" class="mr-2">
                          <label for="member-section" class="">Member</label>
                        </div>
                      <!-- </div> -->
                      <!-- <div class="col-sm"> -->
                        <div ngbButtonLabel class="radio-item">
                          <input ngbButton type="radio" id="non-member-section" [value]="0" class="mr-2" [disabled]="event.member_invited == 3">
                          <label for="non-member-section" class="">Non-Member</label>
                        </div>
                      <!-- </div> -->
                    </div>
                  </form>
                  <!-- <div class="radio-item ">
                    <input type="radio" id="member" name="rad" checked class="mr-2" (click)="memberFlag = true">
                    <label for="member" class="mr-4  font-weight-bold">Member</label>
                  </div>
                  <div class="radio-item">
                    <input type="radio" id="non" name="rad" class="mr-2" (click)="memberFlag = false">
                    <label for="non" class=" font-weight-light">Non-Member</label>
                  </div> -->
                </div>
                <div *ngIf="!memberFlag" class="animated fadeIn">
                  <form  class="">
                    <div class="px-lg-5">
                      <div class="form-row">
                        <div class="form-group col-sm-6">
                          <label class="text-grey f-12 font-weight-bold" for="name">First Name</label>
                          <input type="text" class="form-control" [(ngModel)]="nonMember.first_name" name="first_name" placeholder="first Name"
                            [ngClass]="!nonMemberValidErr.first_name ? '':  'is-invalid'">
                          <div class="invalid-feedback" *ngIf="nonMemberValidErr && nonMemberValidErr.first_name">{{nonMemberValidErr.first_name}}
                          </div>
                        </div>
                        <div class="form-group col-sm-6">
                          <label class="text-grey f-12 font-weight-bold" for="last_name">Last Name</label>
                          <input type="text" class="form-control" [(ngModel)]="nonMember.last_name" name="last_name"
                            placeholder="Last Name" [ngClass]="!nonMemberValidErr.last_name ? '':  'is-invalid'">
                          <div class="invalid-feedback" *ngIf="nonMemberValidErr && nonMemberValidErr.last_name">
                            {{nonMemberValidErr.last_name}}</div>
                        </div>
                      </div>
                      <div class="form-group ">
                        <label class="text-grey f-12 font-weight-bold" for="email">Email</label>
                        <input type="text" class="form-control" [(ngModel)]="nonMember.email" name="email" id="email"
                          placeholder="Email" [ngClass]="!nonMemberValidErr.email ? '':  'is-invalid'">
                        <div class="invalid-feedback" *ngIf="nonMemberValidErr && nonMemberValidErr.email">{{nonMemberValidErr.email}}
                        </div>
                      </div>
                      <!-- <div class="form-group">
                        <label class="text-grey f-12 font-weight-bold" for="phone">Phone Number</label>
                        <input type="text" class="form-control" [(ngModel)]="event.phone" value="{{event.phone_no | phoneFormat}}" name="phone" maxlength="14"
                          placeholder="Phone Number" [ngClass]="!nonMemberValidErr.phone ? '':  'is-invalid'">
                        <div class="invalid-feedback" *ngIf="nonMemberValidErr && nonMemberValidErr.phone">{{nonMemberValidErr.phone}}
                        </div>
                      </div> -->

                      <!-- <div class="mb-4 mt-5">
                        <button type="submit" class="btn btn-block btn-dark-red" [disabled]="loading">
                          <span class="spinner-border spinner-border-sm" *ngIf="loading"></span>&nbsp; <span *ngIf="loading">
                            Loading..</span>
                          <span *ngIf="!loading">Register</span>
                        </button>
                      </div> -->

                    </div>

                  </form>

                </div>

                <div *ngIf="memberFlag" class="animated fadeIn">
                  <form>
                    <div class="input-group rounded search row mb-2">
                      <i class="fa fa-search "></i>
                      <input type="search" class="mt-2 form-control rounded mr-0 pl-0" [(ngModel)]="searchTerm"
                        name="search" placeholder="Search Members" />
                    </div>

                    <div class="bg-white border rounded drop-list ">

                      <perfect-scrollbar>
                        <div class="hei-130 px-3 px-md-4 py-2">
                          <div class="flex-center hei-80 w-100" *ngIf="!(userData.length > 1)">
                            <span>No users found</span>
                          </div>
                          <ng-container *ngFor="let data of userData| filter:searchTerm; let i=index">
                            <div class="align-middle my-2" *ngIf="data.id != event.user_id">
                              <!-- <img src="assets/images/user/avatar-1.jpg" alt="user image" class="img-radius align-top m-r-15">
                              <div class="d-inline-block">
                                <a href="javascript:"><p class="m-b-0">Cheers!</p><span  class="status active"></span></a>
                              </div> -->
                              <div class="media ">
                                <img alt="" class="img-radius mr-2" [src]="data.avatar ? data.avatar: 'assets/images/user/avatar.png'" width="28px" height="28px">
                                <div class="media-body ml-2 my-auto">
                                  <p class="mb-0"><strong>{{data.first_name+ ' ' +(data.last_name? data.last_name: '')}}</strong></p>
                                </div>
                                <div class="text-right my-auto">
                                  <div class="chk-option">
                                    <label class="check-task custom-control custom-checkbox d-flex done-task my-auto">
                                      <input type="checkbox" name="{{data.first_name}}" [disabled]="(!data.isselected && event.member_invited == 3)"
                                        (click)="onChange(data.id,$event.target.checked)" [(ngModel)]="data.isselected"
                                        class="custom-control-input">
                                      <span class="custom-control-label"></span>
                                    </label>
                                  </div>
                                  <!-- <input type="checkbox" [id]="data+i" [name]="data" [(ngModel)]="data.checked" (ngModelChange)="getCheckValues($event,data)">
                              <label [for]="data+i"></label>  -->
                                </div>
                              </div>
                            </div>
                          </ng-container>

                          <!-- <ul class="list-group list-group-flush pt-2">
                            <li class="list-group-item" *ngFor="let data of userData| filter:searchTerm; let i=index">
                              <div class="media"><img alt="Generic placeholder image" class="img-radius" src="{{data.img}}"
                                  width="35px" height="35px">
                                <div class="media-body ml-2 my-auto">
                                  <p class="mb-0"><strong>{{data.name}}</strong></p>
                                </div>
                                <div class="text-right my-auto">
                                  <div class="chk-option"><label
                                      class="check-task custom-control custom-checkbox d-flex done-task"><input type="checkbox"
                                        name="{{data.name}}" (click)="onChange(data.id,$event.target.checked)"
                                        [(ngModel)]="data.isselected" class="custom-control-input"><span
                                        class="custom-control-label"></span></label></div>

                                </div>
                              </div>
                            </li>
                          </ul> -->
                        </div>
                      </perfect-scrollbar>

                    </div>
                  </form>
                </div>

              </div>
            </div>

            <div class="col-12" *ngIf="step == 4">
              <div class="animated fadeIn">
                <div class=" mt-2">
                  <div class="">
                    <p class="text-grey">You have to choose minium (1) or maximium (3) members.* </p>
                  </div>
                  <ul class="list-inline">
                    <li class="list-inline-item wid-100 align-top" *ngFor="let item of details; let i=index;">
                      <div class="wid-50 mx-auto position-relative">
                        <img class="img-radius d-block relative" [src]="item.avatar ? item.avatar: 'assets/images/user/avatar.png'" width="42px" height="42px">
                        <i class="feather icon-x close-style cursor-pointer" (click)="unCheck(item.id,i)"></i>
                      </div>
                      <p class="text-center">{{item.first_name+ ' ' +(item.last_name? item.last_name: '')}}</p>
                    </li>
                    <li class="list-inline-item wid-100 align-top">
                      <div class="wid-50 mx-auto position-relative cursor-pointer" *ngIf="event.member_invited < 3" (click)="step = 3; memberFlag=1; progress = progress-25">
                        <div class="bg-light text-dark-red py-auto img-radius p-3 text-center">
                          <i class="feather icon-plus"></i>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!-- <ng-container *ngFor="let item of details; let i=index;">

                    <div class="wid-50 position-relative">
                      <img class="img-radius mx-auto d-block relative" [src]="item.avatar ? item.avatar: 'assets/images/user/avatar.png'" width="42px" height="42px">
                      <i class="feather icon-x close-style" (click)="unCheck(item.id,i)"></i>
                      <p class="text-center">{{item.name}}</p>
                    </div>

                  </ng-container> -->
                </div>
              </div>
            </div>

          </div>

          <div class="mt-4 row">
            <div class="col-3">
              <button *ngIf="step > 1" type="button" class=" btn btn-default text-secondary float-left p-0"
                (click)="progress = progress-25; step = step-1"><img class="mr-1"
                  src="assets/images/svg/left.svg" width="20px" height="29px">Back
              </button>
            </div>
            <div class="col-6">
              <div *ngIf="step == 3 && !memberFlag" class="text-center">
                <button type="button" class="btn btn-block btn-dark-red" [disabled]="event.member_invited == 3" (click)="addingNonMember(nonMember); progress = progress+25">
                  <span>Send Invitation</span>
                </button>
              </div>
              <div *ngIf="step == 4" class="text-center">
                <button type="submit" class="btn btn-block btn-dark-red" [disabled]="loading || this.event.member_invited < 1" (click)="onSubmit(event)">
                  <span class="spinner-border spinner-border-sm" *ngIf="loading"></span>&nbsp; <span *ngIf="loading">
                    Loading..</span>
                  <span *ngIf="!loading">Confirm</span>
                </button>
              </div>
            </div>
            <div class="col-3">
              <button *ngIf="step < 4 && (step !=3 || memberFlag)" type="button" class=" btn btn-default text-secondary float-right p-0"
                (click)="validateInputs(event)">Next <img class="ml-1"
                  src="assets/images/svg/right.svg" width="20px" height="29px">
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
