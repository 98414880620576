import { environment } from 'src/environments/environment.prod';
import { Router, NavigationEnd } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';
import {Component, Input, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/components/auth-guard/auth.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {EventFormModal} from '../../../../../components/event-form/event-form';
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],


})
export class NavRightComponent implements OnInit {


  currentUser: any = {};
  windowWidth: number;
  url;
  liveUrl;
  flag = false;
  formData: any = new FormData();
  public image: any;
  role;
  notifications: any = [];
  filter = {
    page: 1,
    perpage: 12
  }
  // public image: Array<File> = [];
  
  constructor(public router: Router, private coreService: CoreService, private authService: AuthService, private location: Location, private modalService:NgbModal) {
    if (authService.userData) {
      this.role = authService.userData.role_id;
    }
    this.profileData();
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.getNotification();
      }
    });
  }

  ngOnInit() {
    this.flag = false;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.windowWidth = window.innerWidth;
    this.url = (this.router.url).split('/')[1];
    this.liveUrl = environment.liveURL;
  }

  getNotification() {
    if(this.currentUser.notification) {
      this.coreService.get('notification').subscribe((res: any) => {
        if(res.data) {
          this.notifications = res.data;
        }
      }, err => {
      });
    }
  }
  profileData() {
    if(this.authService.accessTokenId) {
      this.coreService.get('profile').subscribe((res: any) => {
        this.currentUser = res.data;
        this.getNotification();
        this.flag = true;
      }, () => {
      if (this.authService.userData) {
        this.currentUser = this.authService.userData;
        this.flag = true;
      }
      });
    }
  }

  logout() {
    this.authService.clearLocalStorage();
    this.router.navigate(['/login']);
    // location.href = "/";
  }
  events(){
    const modalRef = this.modalService.open(EventFormModal, { size: 'md' });
  }


}
